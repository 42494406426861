import { FilterKeyword } from "mtgql";

export const syntaxDocs: Record<FilterKeyword, string> = {
  "!": "https://scryfall.com/docs/syntax#exact",
  // @ts-ignore
  "++": "https://scryfall.com/docs/syntax#display",
  "@@": "https://scryfall.com/docs/syntax#display",
  and: "/user-guide/query-syntax#and-and-or-operators",
  or: "/user-guide/query-syntax#and-and-or-operators",
  a: "https://scryfall.com/docs/syntax#flavor",
  art: "https://scryfall.com/docs/syntax#tagger",
  artist: "https://scryfall.com/docs/syntax#flavor",
  arttag: "https://scryfall.com/docs/syntax#tagger",
  atag: "https://scryfall.com/docs/syntax#tagger",
  b: "https://scryfall.com/docs/syntax#sets",
  banned: "https://scryfall.com/docs/syntax#legality",
  block: "https://scryfall.com/docs/syntax#sets",
  border: "https://scryfall.com/docs/syntax#frame",
  c: "https://scryfall.com/docs/syntax#colors",
  ci: "https://scryfall.com/docs/syntax#colors",
  cmc: "https://scryfall.com/docs/syntax#mana",
  cn: "https://scryfall.com/docs/syntax#sets",
  color: "https://scryfall.com/docs/syntax#colors",
  commander: "https://scryfall.com/docs/syntax#colors",
  cube: "https://scryfall.com/docs/syntax#cubes",
  cubeo: "https://scryfall.com/docs/syntax#cubes",
  date: "https://scryfall.com/docs/syntax#year",
  devotion: "https://scryfall.com/docs/syntax#mana",
  direction: "https://scryfall.com/docs/syntax#display",
  e: "https://scryfall.com/docs/syntax#sets",
  edition: "https://scryfall.com/docs/syntax#sets",
  eur: "https://scryfall.com/docs/syntax#prices",
  f: "https://scryfall.com/docs/syntax#legality",
  flavor: "https://scryfall.com/docs/syntax#flavor",
  fo: "https://scryfall.com/docs/syntax#oracle",
  format: "https://scryfall.com/docs/syntax#legality",
  frame: "https://scryfall.com/docs/syntax#frame",
  ft: "https://scryfall.com/docs/syntax#flavor",
  function: "https://scryfall.com/docs/syntax#tagger",
  game: "https://scryfall.com/docs/syntax#promos",
  has: "/user-guide/query-syntax#is",
  id: "https://scryfall.com/docs/syntax#colors",
  identity: "https://scryfall.com/docs/syntax#colors",
  in: "https://scryfall.com/docs/syntax#sets",
  include: "https://scryfall.com/docs/syntax#extras",
  is: "/user-guide/query-syntax#is",
  kw: "https://scryfall.com/docs/syntax#oracle",
  keyword: "https://scryfall.com/docs/syntax#oracle",
  lang: "https://scryfall.com/docs/syntax#languages",
  language: "https://scryfall.com/docs/syntax#languages",
  layout: "https://scryfall.com/docs/syntax#",
  loy: "https://scryfall.com/docs/syntax#stats",
  loyalty: "https://scryfall.com/docs/syntax#stats",
  m: "https://scryfall.com/docs/syntax#mana",
  mana: "https://scryfall.com/docs/syntax#mana",
  manavalue: "https://scryfall.com/docs/syntax#mana",
  mv: "https://scryfall.com/docs/syntax#mana",
  name: "https://scryfall.com/docs/syntax#regex",
  new: "https://scryfall.com/docs/syntax#rarity",
  not: "/user-guide/query-syntax#is",
  number: "https://scryfall.com/docs/syntax#sets",
  o: "https://scryfall.com/docs/syntax#oracle",
  oracle: "https://scryfall.com/docs/syntax#oracle",
  oracletag: "https://scryfall.com/docs/syntax#tagger",
  order: "https://scryfall.com/docs/syntax#display",
  otag: "https://scryfall.com/docs/syntax#tagger",
  pow: "https://scryfall.com/docs/syntax#stats",
  power: "https://scryfall.com/docs/syntax#stats",
  powtou: "https://scryfall.com/docs/syntax#stats",
  prefer: "https://scryfall.com/docs/syntax#display",
  produces: "https://scryfall.com/docs/syntax#mana",
  pt: "https://scryfall.com/docs/syntax#stats",
  r: "https://scryfall.com/docs/syntax#rarity",
  rarity: "https://scryfall.com/docs/syntax#rarity",
  restricted: "https://scryfall.com/docs/syntax#legality",
  s: "https://scryfall.com/docs/syntax#sets",
  set: "https://scryfall.com/docs/syntax#sets",
  st: "https://scryfall.com/docs/syntax#sets",
  stamp: "https://scryfall.com/docs/syntax#frame",
  t: "https://scryfall.com/docs/syntax#types",
  text: "https://scryfall.com/docs/syntax#oracle",
  tix: "https://scryfall.com/docs/syntax#prices",
  tou: "https://scryfall.com/docs/syntax#stats",
  toughness: "https://scryfall.com/docs/syntax#stats",
  type: "https://scryfall.com/docs/syntax#types",
  unique: "https://scryfall.com/docs/syntax#display",
  usd: "https://scryfall.com/docs/syntax#prices",
  watermark: "https://scryfall.com/docs/syntax#flavor",
  wm: "https://scryfall.com/docs/syntax#flavor",
  year: "https://scryfall.com/docs/syntax#year",
  scryfallid: "/user-guide/query-syntax#ids",
  oracleid: "/user-guide/query-syntax#ids",
};

export const extensionDocs = {
  alias: "/user-guide/syntax-extension#@alias-&-@use",
  a: "/user-guide/syntax-extension#@alias-&-@use",
  defaultMode: "/user-guide/syntax-extension#@defaultmode",
  dm: "/user-guide/syntax-extension#@defaultmode",
  defaultWeight: "/user-guide/syntax-extension#@defaultweight",
  dw: "/user-guide/syntax-extension#@defaultweight",
  defaultDomain: "/user-guide/syntax-extension#@defaultdomain",
  dd: "/user-guide/syntax-extension#@defaultdomain",
  include: "/user-guide/syntax-extension#@include",
  i: "/user-guide/syntax-extension#@include",
  use: "/user-guide/syntax-extension#@alias-&-@use",
  venn: "/user-guide/syntax-extension#@venn-diagram-search",
};
